.text-color-primary{
     color: $primary;
}

.text-color-secondary{
    color: $secondary;
}

.text-color-success{
    color: $success;
}

.text-color-info{
    color: $info;
}

.text-color-danger{
    color: $danger;
}

.table-responsive-sm {
    min-width: 48rem;
}

.table {
    // border-collapse: separate;
    // border-spacing : 0 1rem;
    // line-height    : 1;
    margin-bottom     : 0px;
    // font-family    : $barlow;
    font-weight       : 400;

    tr {

        td,
        th {
            // border           : 0px;
            // vertical-align   : middle;
            // padding       : 5px 7px;
            padding          : 15px;

            // &:last-child {
            //     text-align: right;
            // }
        }
    }
}

.table {
    tr {
        th {
            // padding     : 0px 5px;
            color       : $headings-color;
            font-weight : 500;
            // font-size: 14px;
        }
    }
}

.table-striped {
    thead {
        tr {
            th {
                padding-bottom: 15px!important;
            }
        }
    }

    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $body-bg;
    }

    tfoot{
        border-top: 2px solid $border-color;
        tr{
            th{
                padding-top: 10px;
            }
        }
    }
}


.title1-modal-event {
    text-align: left;
    color: $primary;
    margin-top: 0px;
    float: left;
    clear: both;
    font-weight: bold;
}

.title2-modal-event {
    text-align: left;
    color: $secondary;
}

.truncate-event {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title3-modal-event {
    text-align: left;
    color: $primary;
    float: left;
    clear: both;
    font-weight: bold;
}

.size-value-row{
    font-size: 14px;
}

.size-inline-row{
    inline-size: 80px;
}

.size-inline-row-150{
    inline-size: 150px;
}

.td-word-wrap{
    word-wrap: break-word;
}

.space-between-cell{
    border-spacing: 10px;
    border-collapse: separate;
}


