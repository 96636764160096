.align-right-base{
  text-align: right;
}

.align-left-base{
  text-align: left;
}

.leaflet-container {
  width: 100%;
  height: 50vh;
}

.margin-bottom-text-danger{
  margin-bottom: 7px;
  font-size: 12px;
  margin-top: -5px;
}

.margin-bottom-text-warning{
  margin-bottom: 7px;
  font-size: 12px;
  margin-top: 3px;
}

.information-cart{
  display: flex;
  justify-content: space-between;
}

.buttons-cart {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.line-through-text {
  text-decoration: line-through;
  color: white;
}

.margin-left-soldout {
  margin-left: 15px;
}

.title-notice{
  font-size: 12px;
}

.dot-category {
  width: 20px;
  height: 20px;
}

.dot-category {
  width: 20px;
  height: 20px;
}

.display-flex-container{
  display: flex;
}

@media (min-width: 1080px) {
  .information-cart{
    display: flex;
    justify-content: space-between;
    height: 20px;
  }
}