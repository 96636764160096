.img-event-detail {
  width: 100%;
  height: 20vw;
  object-fit: cover;
}

.img-event-detail-accordion {
  width: 15vw;
  height: 15vw;
  object-fit: cover;
}

.margin-top-event{
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .img-event-detail {
    width: 100%;
    height: 40vw;
    object-fit: cover;
  }

}