
.ovbl-light,
.ovbl-middle,
.ovbl-dark,
.ovgd-light,
.ovgd-middle,
.ovgd-dark,
.ovwh-light,
.ovwh-middle,
.ovwh-dark,
.ovpr-light,
.ovpr-middle,
.ovpr-dark,
.ovpr {
    position: relative;
	
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	.container,
	.container-fluid{
		position: relative;
		z-index: 1;
	}
}
.ovbl-light:before,
.ovbl-middle:before,
.ovbl-dark:before {
    background: $dark;
}
.ovgd-light:before,
.ovgd-middle:before,
.ovgd-dark:before {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
}
.ovwh-light:before,
.ovwh-middle:before,
.ovwh-dark:before {
    background: #FFF;
}
.ovpr-light:before,
.ovpr-middle:before,
.ovpr-dark:before,
.ovpr:before {
    background:$primary;
}
.ovpr-light:before,
.ovwh-light:before,
.ovgd-light:before,
.ovbl-light:before {
    opacity: 0.3;
}
.ovpr-middle:before,
.ovwh-middle:before,
.ovgd-middle:before,
.ovbl-middle:before {
    opacity: 0.5;
}
.ovpr-dark:before,
.ovwh-dark:before,
.ovgd-dark:before,
.ovbl-dark:before {
    opacity: 0.9;
}

// OVERLAY BOX
.overlay-bx {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
	@include transitionSlow;
	
	.align-b{
		height: auto;
		left: 0;
		list-style: outside none none;
		margin: 0;
		position: absolute;
		top:auto;
		bottom: 0;
		width: 100%;
		transform: translate(0);
		-o-transform: translate(0);
		-moz-transform: translate(0);
		-webkit-transform: translate(0);
	}
	.align-m {
		height: auto;
		left: 50%;
		list-style: outside none none;
		margin: 0;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		width: 100%;
		padding: 0 10px;
	}
}
.overlay-icon{
    height: auto;
    left: 50%;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
	padding: 0 10px;
	a {
		display: inline-block;
		padding: 0;
		margin: 0 2px;
		i{
			background-color: #FFF;
		}
	}
}