.card {
    background: #fff;
    width: 18em;
    border-radius: 0.6em;
    margin: 1em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
    height: 25em;
}

.card:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card-data {
    text-align: left;
}

.button-circle-info{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 10px;
    left: 5px;
    background-color: $secondary;
    position: absolute;
}

.p-card-thick {
    color: $primary;
    font-weight: 800;
    white-space: normal;
    font-size: 16px;
}

.p-card-thick-sample {
    color: $primary;
    white-space: normal;
    font-size: 16px;
}

.p-card-thick-modal {
    color: $primary;
    font-weight: 800;
    font-size: 16px;
}

.li-color-card{
    color: #333333;
    font-size: 14px;
}

.li-color-card-modal{
    color: #333333;
    font-size: 16px;
}

.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.info::before {
    font-family: "Font Awesome 5 Free";
    font-size: 15px;
    content: "\f129";
}

.slick-track {
    display: flex;
    position:relative;
    float:left;
    width:100%;
}

.img-event {
    width: 100%;
    height: 12vw;
    object-fit: cover;
}

.img-event-sidebar {
    width: 10vw;
    height: 7vw;
    object-fit: cover;
    padding-bottom: 10px;
}

.img-num-event-selected{
     cursor: pointer;
     height: 35px;
 }

.icon-size-25{
    height: 25px;
}

.slick-slider {
    align-items: justify;
    max-width: 1200px;
    margin: 0 auto;
}

.slick-slide {
    padding-right: 10px;
}

.slick-slide:last-child {
    padding-right: 0;
}

.slick-slide img {
    display: block;
}

.slick-list {
    margin: 0 15px;
    width:100%;
    padding-right:15px;
}

.product-actions {
    display: flex;
    justify-content: space-between;
}

.prevArrow {
    position: absolute;
    top: -250px;
    left: -35px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
}

.nextArrow {
    position: absolute;
    top: -250px;
    right: -50px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
}

.list-group-horizontal > .list-group-item.active > h5{
    color:white !important;
}


.list-group-horizontal > .list-group-item > h5{
    color:black !important;
}

.includer{
    position: relative;
}

.badge.badge-success{
    background-color: $secondary;
    top: 0.5em;
    left: 10px;
    position: absolute;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.inside-button-icon-right-card {
    font-size: 18px;
}

@media screen and (min-device-width:900px) {
    .nextArrow {
        visibility: visible;
    }
    .prevArrow {
        visibility: visible;
    }
}

@media screen and (max-device-width:899px) {
    .nextArrow {
        visibility: hidden;
    }
    .prevArrow {
        visibility: hidden;
    }
}

@media screen and (min-device-width:769px) and (max-device-width:1024px) {
    .card {
        width: 14em;
        height: 14em;
    }
}

@media screen and (min-device-width:769px) and (max-device-width:1024px) {
    .prevArrow {
        font-size: 1rem;
    }

    .nextArrow {
        font-size: 1rem;
    }
    .card {
        width: 12em;
        height: 14em;
    }
}

@media screen and (max-width: 768px) {
    .card {
        width: 10em;
        height: 16em;
    }
    .img-num-event-selected{
        height: 30px;
    }
    .img-event-sidebar {
        width: 30vw;
        height: 20vw;
        object-fit: cover;
        padding-bottom: 10px;
    }
    .img-event-sidebar {
        width: 30vw;
        height: 20vw;
        object-fit: cover;
        padding-bottom: 10px;
    }
    .margin-left-deselect-event {
        margin-left: 20px;
    }
    .p-card-thick-sample {
        color: $primary;
        white-space: normal;
        font-size: 14px;
    }
}

@media screen and (min-device-width:901px) and (max-width: 1024px) {
    .img-event {
        width: 100%;
        height: 10vw;
        object-fit: cover;
    }
}

@media screen and (min-device-width:768px) and (max-width: 900px) {
    .img-event {
        width: 100%;
        height: 12vw;
        object-fit: cover;
    }
}

@media screen and (min-device-width:500px) and (max-width: 767px) {
    .img-event {
        width: 100%;
        height: 18vw;
        object-fit: cover;
    }
}

@media screen and (min-device-width:10px) and (max-width: 499px) {
    .img-event {
        width: 100%;
        height: 28vw;
        object-fit: cover;
    }
}

@media screen and (min-device-width:1025px) and (max-device-width:1199px) {
    .card {
        width: 18em;
        height: 16em;
    }
}

@media screen and (min-device-width:1200px) and (max-device-width:1600px) {
    .card {
        width: 18em;
        height: 22em;
    }
}

@media screen and (max-width: 1270px) {
    .p-card-nowrap {
        /*white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inherit;*/
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        //white-space: normal;
    }
}





/*-------------------------------------start section scroll Horizontal-------------------------------------------*/

.scroll-horizontal{
    display: inline-block;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: default;
    //overflow: hidden;
}

.scroll-horizontal::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(156, 156, 156, .6);
}

::-webkit-scrollbar {
    /*display: none;*/
}

.scrollList{
    background-color: #ffffff;
    margin-left: 10px;
    padding: 5px;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    white-space: nowrap;
    scrollbar-width: none;
}

.scrollList::-webkit-scrollbar{
    display: none;
}

.scrollList .scroll-horizontal-category{
    margin-left: 0px;
}

.scrollList .scroll-horizontal-size-350{
    width: 350px;
    margin-left: 15px;
}

.scrollList .scroll-horizontal-size-300{
    width: 300px;
    margin-left: 15px;
}

.scrollList .scroll-horizontal-size-235{
    width: 235px;
    margin-left: 15px;
}

.scrollList .image-cropper img {
    min-width: 0px;
}

.flex-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: none;
}

.flex-container::-webkit-scrollbar {
    display: none;
}

/*-------------------------------------end section scroll Horizontal-------------------------------------------*/
